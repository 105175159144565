<template>
  <div class="Marketing-wrap">
    <NavBar :title="$route.query.category_name"/>
    <div class="content">
      <div class="gaoButton" @click="$router.push({name:'addText',query:$route.query})"><i><van-icon name="plus" color="#fff" /></i>添加短信</div>
      <h2>短信列表</h2>
      <div class="child" v-for="(item, index) in smslist" :key="index">
        <p>{{ item.template }}</p>
        <div>
          <span>本短信{{item.template.length}}字，分{{Math.ceil(item.template.length/60)}}条发送</span>
          <div>
            <i @click="templateDelete(item.id)">删除</i>
            <i @click="usingTemplates(item)">使用模板</i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import table from '@/api/table'
import NavBar from '@/components/NavBar'
export default {
  name: 'Marketing',
  components:{NavBar},
  data() {
    return {
      smslist: []
    };
  },
  mounted() {
    // document.title = this.$route.query.category_name
    // console.log(this.$route.meta.title)
    this.templateList(parseInt(this.$route.query.category_id))
  },
  methods: {
    ...mapMutations(['updateSMScontent']),
    usingTemplates(content){
      this.updateSMScontent(content)
      this.$router.push('groupSMSs')
    },
    // 短信-删除
    templateDelete(id){
      table.templateDelete({id}).then(response=>{
        if(response.data.result){
          this.templateList(parseInt(this.$route.query.category_id))
        }
      })
    },
    // 短信-列表
    templateList(category_id) {
      table.templateList({ category_id }).then(response => {
        this.smslist = response.data.list;
        console.log(response.data.list);
      });
    }
  }
}
</script>
<style lang="scss" scoped>
  .Marketing-wrap {
    .content{
      padding: 30px;
      h2{
        font-size: 26px;
        font-weight: 500;
        color: #999999;
        margin: 38px 0 27px 0;
      }
      .child {
        background: #fff;
        padding: 27px 35px;
        border-radius: 16px;
        margin-bottom:20px;
        p {
          color: #333333;
          font-size: 26px;
          white-space: pre-wrap; /*css-3*/
          white-space: -moz-pre-wrap; /*Mozilla,since1999*/
          white-space: -pre-wrap; /*Opera4-6*/
          white-space: -o-pre-wrap; /*Opera7*/
          word-wrap: break-word; /*InternetExplorer5.5+*/
        }
        >div{
          margin-top: 40px;
          display: flex;
          justify-content: space-between;
          span{
            font-size: 24px;
            font-weight: 500;
            color: #999999;
          }
          >div{
            i:nth-child(1){
              margin-right: 25px;
            }
            i{
              font-size: 24px;
              font-weight: bold;
              color: #2C6BF8;
            }
          }
        }
      }
    }
    .gaoButton{
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      height: 95px;
      border: 2px solid #E9E9E9;
      border-radius: 10px;
      font-size: 28px;
      font-weight: bold;
      color: #444745;
      >i{
        background:#FE4600;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px
      }
    }
  }
</style>
